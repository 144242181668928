import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import SubHeader from './SubHeader';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 32px;  
  padding-bottom: 32px;  
`;

const ButtonSection = styled.div`
	margin: 0px 15px;
`;


const CityButtonWrapper = styled.ul`
    margin: 0px;
    padding: 0px;
    overflow-x: auto;
`;

const SectionTitle = styled.div`
    font-size: 22px;
    font-weight: 700;
    line-height: 135%;
    color: #141719;
    padding: 8px 0px 16px 8px;
`;


const SectionSubTitle = styled.div`
    font-size: 17px;
    font-weight: 500;
    line-height: 135%;
    color: #141719;
    padding: 6px 0px 16px 8px;
`;


const SelectButton = styled.li`
    cursor: pointer;
    list-style: none;
    display: flex;
    flex: 0 0 auto;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 56px;
    margin: 5px 3px;

    white-space: nowrap; /* 자동 줄바꿈 방지 */
    overflow: hidden; /* 초과 내용 감춤 */
    text-overflow: ellipsis; /* 초과 내용 생략 기호(...)로 표시 */

    padding: 8px 14px;
    border-radius: 20px;
    font-size: 15px;
    line-height: 138%;
    font-weight: 600;
    background-color: #f8f9fa;
    color: #141719;

    background-color: ${props => (props.isSelected ? '#141719' : '#f8f9fa')};
    color: ${props => (props.isSelected ? '#ffffff' : '#141719')};
    transition: background-color 0.2s ease-out, color 0.2s ease-out;


    outline: none;
    box-sizing: border-box;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
    overflow-anchor: none;
`;




const NextWrpper = styled.div`
position: fixed;
left: 0px;
right: 0px;
bottom: 20px;
margin: 0px auto;
padding: 0px 20px;
max-width: 480px;

`;
const NextButton = styled.button`
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    line-height: 1.25;
    border-radius: 28px;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    height: 53px;
    font-size: 20px;
    padding: 0px 14px;
    background-color: #2b96ed;
    color: #ffffff;
    font-weight: 700;
    border:none;
    opacity: ${props => (props.hasSelection? 1 : 0)}; 
    pointer-events: ${props => (props.hasSelection? 'auto' : 'none')}; /* pointer-events 속성 추가 */


    transition: opacity 0.25s ease-in-out; /* opacity 속성만 transition 적용 */

    outline: none;

`;




function SingleSelectOptions({title, title2, subTitle, cityMeta, landmark, setCityMeta, pageNum, selectedOptions,
    curCityIndex, 
    optionsList, isLastQuestion
    , selectedCityList, func}) {

    const [selectedButton, setSelectedButton] = useState([]);
    const hasSelection = selectedButton.length > 0;
    const navigate = useNavigate();
    const isLastCity = selectedCityList.length === (curCityIndex+1);
    let cityName = selectedCityList[curCityIndex];
    let buttonInput;

    useEffect(() => {
        if (Array.isArray(selectedCityList) && selectedCityList.length === 0) {
          navigate('/');
        }
      }, [selectedCityList, navigate]);
    
    /*
    // cityMeta 변경 될때마다 로컬 스토리지 저장
    useEffect(()=>{
        localStorage.setItem('cityMeta', JSON.stringify(cityMeta))
    }, [cityMeta])
    */

    const findEngName = (name) => {
        const city = cityMeta.find(city => city.name === name);
        return city ? city.engName : null;
    }



    const handleNextButton = (event) => {
        if (!selectedButton) {
            return;
        }

        let url;
        url = isLastQuestion ? (isLastCity ? '/calculate' : `/${findEngName(selectedCityList[curCityIndex+1])}/1`) : `/${findEngName(selectedCityList[curCityIndex])}/${pageNum+1}`;
        navigate(url);
        
        buttonInput = selectedButton === optionsList[0];

        if (selectedButton ==='우메다')
            buttonInput = 'umeda';
        else if (selectedButton === '난바')
            buttonInput = 'nanba';
        else if (selectedButton === '기타 지역')
            buttonInput = 'etc';

        func({cityName, setCityMeta, cityMeta, selectedOptions, buttonInput});
        setSelectedButton([]);
      }

    const handleClick = (buttonName) => () => {
        setSelectedButton(buttonName);
      };
 

	return (
		<Wrapper>
            <SubHeader title={cityName}/>
            <ButtonSection> 
            {title && (
                <SectionTitle>
                    {title}{title2 && <br />}{title2}
                </SectionTitle>
                )
                }
                {subTitle && (
                <SectionSubTitle>
                        {subTitle}
                </SectionSubTitle>
                )}

            <div>
                <CityButtonWrapper> 

                    {optionsList && (optionsList.map((option) => (
                        <SelectButton
                        key={option}
                        onClick={handleClick(option)}
                        isSelected={selectedButton === option}
                        >
                        {option}
                        </SelectButton>
                    ))
                    )}
                </CityButtonWrapper>
            </div>
            </ButtonSection> 


        <NextWrpper>
                <NextButton hasSelection={hasSelection} onClick={handleNextButton}>다음</NextButton>
          </NextWrpper>


		</Wrapper>
	)
}

export default React.memo(SingleSelectOptions);


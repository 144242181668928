import './App.css';
import { useState } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { cityMetaData, cityListData, osakaLandmarkData, kyotoLandmarkData, selectedOptionsData } from './data.js';
import Main from './Main';
import MultiSelectOptions from './MultiSelectOptions';
import SingleSelectOptions from './SingleSelectOptions';
import Calculate from './Calculate';
import Result from './Result';
import Cover from './Cover';
import {editCityIsMultiRegion, editOsakaIsHeavyTourist, editCityIsOneDay, editNaraIsMultiCity, editKyotostayLocation} from './cityMetaFunctions';
import ReactGA from "react-ga";

let Wrapper = styled.div`
  width: 100%;  
  height: 100%;  
  max-width: 480px;  
  margin: 0 auto;
`;

let HomeButton = styled.button`
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    line-height: 1.25;
    border-radius: 28px;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    height: 53px;
    font-size: 20px;
    padding: 0px 14px;
    background-color: #2b96ed;
    color: #ffffff;
    font-weight: 700;
    border:none;
    opacity: 1; 
    pointer-events: auto; /* pointer-events 속성 추가 */


    transition: opacity 0.25s ease-in-out; /* opacity 속성만 transition 적용 */

    outline: none;
  `;

let HomeWrpper = styled.div`
position: relative;
left: 0px;
right: 0px;
top : 20px;
bottom: 20px;
margin: 0px auto;
padding: 0px 20px;
max-width: 480px;

`;

const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID; // 환경 변수에 저장된 추적ID 가져오기
ReactGA.initialize(gaTrackingId); // react-ga 초기화 및 debug 사용
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {

  let [selectedCityList, setSelectedCityList] = useState([]);
  let [cityMeta, setCityMeta] = useState(cityMetaData);
  let [selectedOptions, setSelectedOptions] = useState(selectedOptionsData);

  const navigate = useNavigate();
  
  return (
    <Wrapper>
      <Routes>
        <Route path="/" element={
          <Cover/>
        }/>
        {/* 메인 페이지. 오사카 도시 고르기 */}
        <Route path="/main" element={

          <Main 
          cityMeta={cityMeta} 
          OptionsList={cityListData} 
          setSelectedCityList={setSelectedCityList}
          selectedCityList={selectedCityList}
          
          />
        } />

        {/* 오사카 */}
        <Route path="/osaka" >
          <Route path="1" element={ 
            <MultiSelectOptions
            title='방문하려는 여행지를'
            title2='모두 골라주세요'
            cityMeta={cityMeta}
            setCityMeta={setCityMeta}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            pageNum={1}
            optionsList={osakaLandmarkData}
            isLastQuestion={false}
            selectedCityList={selectedCityList}
            isLastCity={(selectedCityList.findIndex((item) => item === '오사카 시내')+1) === selectedCityList.length}
            curCityIndex={selectedCityList.findIndex((item) => item === '오사카 시내')}
            func={editCityIsMultiRegion}
            order={['난바', '우메다', '텐노지', '기타']}
            etcGroup={['오사카성','오사카코','USJ']}
            />}
            />

          <Route path="2" element={ 
            <SingleSelectOptions
            title='어떤 여행 스타일이신가요?'
            title2={null}
            cityMeta={cityMeta}
            setCityMeta={setCityMeta}
            pageNum={2}
            selectedOptions={selectedOptions}
            optionsList={['하루에 3곳 이상 열심히 다닐거에요','여유롭게 다닐래요']}
            isLastQuestion={true}
            selectedCityList={selectedCityList}
            isLastCity={(selectedCityList.findIndex((item) => item === '오사카 시내')+1) === selectedCityList.length}
            curCityIndex={selectedCityList.findIndex((item) => item === '오사카 시내')}
            func={editOsakaIsHeavyTourist}
            />}
            />
       
        </Route>

        {/* 교토 */}
        <Route path="/kyoto">
          <Route path="1" element={ 
            <SingleSelectOptions
            title='교토에는'
            title2='며칠 방문하시나요?'
            cityMeta={cityMeta}
            setCityMeta={setCityMeta}
            pageNum={1}
            selectedOptions={selectedOptions}
            optionsList={['당일 치기','1박 이상']}
            isLastQuestion={false}
            selectedCityList={selectedCityList}
            isLastCity={(selectedCityList.findIndex((item) => item === '교토')+1) === selectedCityList.length}
            curCityIndex={selectedCityList.findIndex((item) => item === '교토')}
            func={editCityIsOneDay}
            />}
            />

          <Route path="2" element={ 
            <MultiSelectOptions
            title='방문하는 여행지를'
            title2='모두 골라주세요'
            cityMeta={cityMeta}
            setCityMeta={setCityMeta}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            pageNum={2}
            optionsList={kyotoLandmarkData}
            isLastQuestion={false}
            selectedCityList={selectedCityList}
            isLastCity={(selectedCityList.findIndex((item) => item === '교토')+1) === selectedCityList.length}
            curCityIndex={selectedCityList.findIndex((item) => item === '교토')}
            func={editCityIsMultiRegion}
            order={['교토 시내', '은각사 근처', '기타']}
            etcGroup={['금각사','아라시야마','후시미이나리 신사']}
            />}
            />

          <Route path="3" element={ 
            <SingleSelectOptions
            title='어디서 출발하시나요?'
            title2={null}
            cityMeta={cityMeta}
            setCityMeta={setCityMeta}
            pageNum={3}
            selectedOptions={selectedOptions}
            optionsList={['우메다','난바', '기타 지역']}
            isLastQuestion={true}
            selectedCityList={selectedCityList}
            isLastCity={(selectedCityList.findIndex((item) => item === '교토')+1) === selectedCityList.length}
            curCityIndex={selectedCityList.findIndex((item) => item === '교토')}
            func={editKyotostayLocation}
            />}
            />
           
        </Route>

        {/* 고베 */}
        <Route path="/kobe">
            <Route path="1" element={ 
            <SingleSelectOptions
            title='고베에는'
            title2='며칠 방문하시나요?'
            cityMeta={cityMeta}
            setCityMeta={setCityMeta}
            pageNum={1}
            selectedOptions={selectedOptions}
            optionsList={['당일치기','1박 이상']}
            isLastQuestion={true}
            selectedCityList={selectedCityList}
            isLastCity={(selectedCityList.findIndex((item) => item === '고베')+1) === selectedCityList.length}
            curCityIndex={selectedCityList.findIndex((item) => item === '고베')}
            func={editCityIsOneDay}
            />}
            />
           
        </Route>


        {/* 나라 */}
        <Route path="/nara" >
        
            <Route path="1" element={ 
            <SingleSelectOptions
            title='다른 오사카 근교 도시도'
            title2='같은날에 함께 방문하시나요?'
            cityMeta={cityMeta}
            setCityMeta={setCityMeta}
            pageNum={1}
            selectedOptions={selectedOptions}
            optionsList={['네. 교토나 고베도 같이 방문해요','아니요. 나라만 방문해요']}
            isLastQuestion={true}
            selectedCityList={selectedCityList}
            isLastCity={(selectedCityList.findIndex((item) => item === '나라')+1) === selectedCityList.length}
            curCityIndex={selectedCityList.findIndex((item) => item === '나라')}
            func={editNaraIsMultiCity}
            />}
            />
        </Route>


        {/* 결과 계산 */}
        <Route path="/calculate" element={
            <Calculate 
            selectedCityList={selectedCityList}
            cityMeta={cityMeta}
            selectedOptions={selectedOptions}
            />
        } />


        {/* 결과 */}
        <Route path="/result" element={
            <Result 
            selectedCityList={selectedCityList}
            cityMeta={cityMeta}
            selectedOptions={selectedOptions}
            setCityMeta={setCityMeta}
            setSelectedCityList={setSelectedCityList}
            setSelectedOptions={setSelectedOptions}
            />
        } />

        {/* 404 */}
        <Route path="*" element={
          <div>
            <HomeWrpper>
              <HomeButton onClick={()=>{ navigate('/')}}>홈으로 돌아가기</HomeButton>
            </HomeWrpper>
          </div>
        } />
      </Routes>
    </Wrapper>
  );
}

export default App;



import React, { useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import { useNavigate } from 'react-router-dom';


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 32px;  
  padding-bottom: 32px;  
`;

const ButtonSection = styled.div`
	margin: 30px 15px;
`;

const SectionTitleWithSub = styled.div`
    font-size: 22px;
    font-weight: 700;
    line-height: 135%;
    color: #141719;
    text-align: center;
    padding: 40px 0px 30px 0px;
`;


const LoadingAnimation = keyframes`
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
`;

const LoadingText = styled.p`
  font-size: 22px;
  font-weight: 700;
  line-height: 135%;
  color: #141719;
  text-align: center;
  ::after {
    content: '';
    animation: ${LoadingAnimation} 1s infinite;
    display: inline-block;
    width: 1em;
    text-align: left;
  }
`;



function Calculate({selectedCityList, cityMeta, selectedOptions}) {
    const navigate = useNavigate();

    
    
    useEffect(() => {
        if (Array.isArray(selectedCityList) && selectedCityList.length === 0) {
          navigate('/');
        }

        localStorage.setItem('cityMeta', JSON.stringify(cityMeta))
        localStorage.setItem('selectedCityList', JSON.stringify(selectedCityList)) 
        localStorage.setItem('selectedOptions', JSON.stringify(selectedOptions))

        const timeoutId = setTimeout(() => {
            navigate('/result');
          }, 2000);
      
          // 컴포넌트가 unmount될 때 timeout을 clear합니다.
          return () => clearTimeout(timeoutId);
      }, [selectedCityList, navigate, cityMeta, selectedOptions]);

	return (
		<Wrapper>  
            <ButtonSection> 
                {selectedCityList && (
                    <>
                    <SectionTitleWithSub>두근두근...!</SectionTitleWithSub>
                    <LoadingText>여행 일정에 맞는 <br/>최적의 패스를 찾고 있어요</LoadingText>
                    </>
                    )
                  }
            </ButtonSection> 
		</Wrapper>
	)
}

export default React.memo(Calculate);


import React, { useEffect } from 'react';
import styled from "styled-components";
import parse from "html-react-parser"

import { useNavigate } from 'react-router-dom';
import {getResultIndex, getRegionFromOptions, getOptionsListWithPrice} from './cityMetaFunctions';
import ResultSubHeader from './ResultSubHeader';

import { resultMetaData } from './data.js';


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f1f3f5;
`;

const SectionContainer = styled.div`
    padding: 30px 15px;
`;


const CitySectionTitle = styled.div`
    font-size: 22px;
    font-weight: 700;
    line-height: 135%;
    color: #141719;
    margin-bottom: 10px;
`;

const Content = styled.div`
    font-size: 17px;
    font-weight: 500;
    line-height: 135%;
    color: #141719;
    margin: 12px 0px;
`;



const Section = styled.div`
    width: 100%;
    margin-bottom: 2px;

    background-color: white;
    border-radius: 34px;
    overflow: hidden;
    padding: 28px 20px 30px 20px;
    box-sizing: border-box;

`;

const CallToActionWrapper = styled.div`

grid-template-columns: repeat(2, 1fr);
margin: 0px 8px;
    gap: 0px;
    display: grid;
`;


const NextWrpper = styled.div`

margin: 0px auto;
max-width: 480px;
display : flex;
justifyContent : space-between;

`;
const NextButton = styled.button`
    position: relative;
    // display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    line-height: 1.25;
    border-radius: 28px;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    height: 50px;
    font-size: 18px;
    padding: 0px 14px;
    background-color: #2b96ed;
    color: #ffffff;
    font-weight: 700;
    border:none;
    // opacity: ${props => (props.hasSelection > 0? 1 : 0)}; 
    // pointer-events: ${props => (props.hasSelection > 0 ? 'auto' : 'none')}; /* pointer-events 속성 추가 */


    transition: opacity 0.25s ease-in-out; /* opacity 속성만 transition 적용 */

    outline: none;

`;
const NextButtonBlack = styled.button`
    position: relative;
    // display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    line-height: 1.25;
    border-radius: 28px;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    height: 50px;
    font-size: 18px;
    padding: 0px 14px;
    background-color: #141719;
    color: #ffffff;
    font-weight: 700;
    border:none;
    // opacity: ${props => (props.hasSelection > 0? 1 : 0)}; 
    // pointer-events: ${props => (props.hasSelection > 0 ? 'auto' : 'none')}; /* pointer-events 속성 추가 */


    transition: opacity 0.25s ease-in-out; /* opacity 속성만 transition 적용 */

    outline: none;

`;

const CautionBox = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    flex-basis: 100%;
    background: rgb(245, 246, 247);
    padding: 18px 20px;
    border-radius: 24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
`;

const Caution = styled.div`
    font-weight: 500;
    margin-bottom: 5px;
`;
const Bold = styled.span`
    font-weight: 700;
    margin-bottom: 5px;
`;

const List = styled.ul`
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 15px;
`;

const ProductLink = styled.a`
display: block;
padding: 12px 6px 24px;
text-decoration: none;
color: rgb(20, 23, 25);
`;

const ProductThumbnail = styled.div`
display: block;
position: relative;
width: 100%;
height: 0px;
padding-top: 100%;
border-radius: 12px;
overflow: hidden;
`;

const ProductImgWrapper = styled.div`
display: inline-flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const ProductImg = styled.img`
display: inline-flex;
overflow: hidden;
position: relative;
inset: 0px;
width: inherit;
height: inherit;
min-width: inherit;
min-height: inherit;
object-fit: cover;
`;
const ProductTextWrapper = styled.span`
display: block;
margin-top: 8px;
padding: 0px 6px;


`;

const ProductTitle = styled.span`
    margin: 0px 0px 2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: var(--gray-1000);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const ProductReviews = styled.div`
display: flex;
-webkit-box-align: center;
align-items: center;
`;

const ProductStar = styled.img`
display: inline-block;
border: 0px;
width: 14px;
height: 14px;
vertical-align: middle;
`;

const ProductScore = styled.span`
color: var(--gray-1000);
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-left: 4px;
    letter-spacing: -0.01em;
`;

const ProductReviewsNumber = styled.span`
margin-left: 2px;
    font-size: 14px;
    font-weight: 500;
    color: var(--gray-700);
`;

const ProductPrice = styled.span`
    display: block;
    margin-top: 2px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: var(--gray-1000);
`;



function Result({selectedCityList, cityMeta, selectedOptions, setSelectedOptions, setCityMeta, setSelectedCityList}) {
    const navigate = useNavigate();

    const handleCopyClipBoard = async (text) => {
        // try {
        //     await navigator.clipboard.writeText(text);
        //     alert("클립보드에 링크가 복사되었어요.");
        // } catch (err) {
        //     console.log(err);
        // }

        const element = document.createElement('textarea');
        element.value = text;
        document.body.appendChild(element);
        element.select();
                
        try {
            const successful = document.execCommand('copy');
            if (successful) {
            alert('텍스트가 복사되었습니다.');
            } else {
            alert('텍스트 복사에 실패했습니다.');
            }
        } catch (err) {
            console.error('텍스트 복사에 실패했습니다.', err);
        }
        
        document.body.removeChild(element);
        
        // navigator.clipboard
        //   .writeText(text)
        //   .then(() => {
        //     alert("클립보드에 링크가 복사되었어요!");
        //   })
        //   .catch(() => {
        //     alert("복사에 실패 했어요");
        //   });

      };
    
    useEffect(() => {
        // selectedCityList 비엇는지 확인
        if (Array.isArray(selectedCityList) && selectedCityList.length === 0) {
            // localstorage 조회
            let localStorageSelectedCityList = localStorage.getItem('selectedCityList');
            localStorageSelectedCityList = JSON.parse(localStorageSelectedCityList);
            
            // 로컬 스토리지에 SelectedCityList 비엇는지 확인
            if (Array.isArray(localStorageSelectedCityList) && localStorageSelectedCityList.length !== 0) {
                // 안비엇으면 state 채워줌
                let localStorageCityMeta = localStorage.getItem('cityMeta');
                localStorageCityMeta = JSON.parse(localStorageCityMeta);

                let localStorageSelectedOptions = localStorage.getItem('selectedOptions');
                localStorageSelectedOptions = JSON.parse(localStorageSelectedOptions);

                setCityMeta(localStorageCityMeta);
                setSelectedCityList(localStorageSelectedCityList);
                setSelectedOptions(localStorageSelectedOptions);
            } else {
                navigate('/');
            }
        }
      }, [selectedCityList, navigate, setCityMeta, setSelectedCityList, setSelectedOptions]);
    


    const handleNextButton = (event) => {
    
        navigate('/');
        // func(cityMeta);
        // func({cityName, selectedButtons, setCityMeta, cityMeta, selectedOptions, setSelectedOptions, optionsList})
    };

    return (
        <Wrapper>
            {selectedCityList && (selectedCityList.map((cityName) => (

                           <Section  key={cityName}>
                            <SectionContainer> 
                            <ResultSubHeader title={cityName}/>
                            <CitySectionTitle>

                                {parse(resultMetaData[getResultIndex({resultMetaData,cityName,cityMeta,selectedOptions})].title)}
                            </CitySectionTitle>


                            <List>
                                {resultMetaData[getResultIndex({resultMetaData,cityName,cityMeta,selectedOptions})].reason.map((reason, index) => (
                                <li>
                                <Content key={index}>
                                    {["오사카 시내","교토"].includes(cityName) ? (
                                        parse(reason
                                            .replace("[지역]", getRegionFromOptions({ selectedOptions, cityName }).join(', '))
                                            // .replace("[할인비용]", getRegionFromOptions({ selectedOptions, cityName }).join(', '))
                                            .replace("[방문지]", getOptionsListWithPrice({selectedOptions, cityName}))
                                            .replace("[비용]", cityMeta[cityMeta.findIndex((item) => item.name === cityName)].attributes.passSum)
                                            )
                                    ) : (
                                        parse(reason)
                                    )}                           
                                </Content>
                                </li>
                                ))}
                            </List>
                                                                 
                        {resultMetaData[getResultIndex({resultMetaData,cityName,cityMeta,selectedOptions})].caution &&
                                <CautionBox>
                                    <Bold>알아두면 좋아요</Bold>
                                    {
                                        cityName === '오사카 시내' && cityMeta[cityMeta.findIndex((item) => item.name === cityName)].attributes.isUsj ?
                                       <Caution>- 유니버셜 스튜디오는 주로 하루 종일 있게되니까 별도의 교통권과 입장권을 사서 가시는게 이득이에요!</Caution> : <></>
                                    }
                                    {
                                        cityName === '교토'
                                        && cityMeta[cityMeta.findIndex((item) => item.name === cityName)].attributes.isOneDay 
                                        && (
                                            selectedOptions[selectedOptions.findIndex((item) => item.name === "교토")].options.includes("아라시야마")
                                            || selectedOptions[selectedOptions.findIndex((item) => item.name === "교토")].options.includes("후시미이나리 신사")
                                        ) ?
                                        <Caution>- <strong>아라시야마</strong>를 가신다면 <strong>한큐 패스</strong>, <strong>후시미이나리 신사</strong>로 가신다면 <strong>게이한 패스</strong>를 이용하시는 게 좋아요!</Caution> : <></>
                                    }
                                    {resultMetaData[getResultIndex({resultMetaData,cityName,cityMeta,selectedOptions})].caution.map((caution, index) => (
                                        <Caution key={index}>
                                        - {caution
                                            .replace("[옵션 가격]", getOptionsListWithPrice({selectedOptions, cityName}))
                                        }
                                        </Caution>
                                    ))}

                                </CautionBox>
                                }
                        
                            </SectionContainer> 
                            <CallToActionWrapper>
                            {resultMetaData[getResultIndex({resultMetaData,cityName,cityMeta,selectedOptions})].product.map((product, index)=> (
                             <ProductLink href={product.url} target="_blank">
                                  <ProductThumbnail>
                                    <ProductImgWrapper>
                                        <ProductImg src={product.image_url}/>
                                    </ProductImgWrapper>
                                </ProductThumbnail>
                                <ProductTextWrapper>
                                     <ProductTitle>
                                     {product.name}
                                     </ProductTitle> 
                                     <ProductReviews>
                                        <ProductStar src="https://dffoxz5he03rp.cloudfront.net/icons/ico_star.svg"/>
                                        <ProductScore>{product.review_score.toFixed(1)}</ProductScore>
                                        <ProductReviewsNumber>({product.review_count.toLocaleString()})</ProductReviewsNumber>
                                     </ProductReviews>
                                     <ProductPrice>
                                        {product.price}
                                     </ProductPrice>
                                  </ProductTextWrapper>
                             </ProductLink>   
                            ))}
                        </CallToActionWrapper>
                        
                           </Section>
            ))
            )}
                 
          
        <NextWrpper>
                    <NextButtonBlack onClick={handleNextButton}>처음으로 돌아가기</NextButtonBlack>

                    <NextButton onClick={() => handleCopyClipBoard(`${window.location.href.replace('/result', '')}`)}
>공유하기</NextButton>

        </NextWrpper>
        </Wrapper>


    )


}

export default React.memo(Result);



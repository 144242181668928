import styled from "styled-components";

let CityIndicator = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 4px;
  align-content: flex-end;
  margin-bottom: 6px;
}
`;

let IndicatorText = styled.span`

display: inline-block;
text-align: center;
border-radius: 6px;
padding: 1.5px 4.5px;
font-size: 13px;
font-weight: 600;
line-height: 138%;
letter-spacing: -0.02em;
color: rgb(255, 255, 255);
background-color: ${props => {
  switch(props.title) {
    case '오사카 시내':
      return '#51ABF3';
    case '교토':
      return '#33B893';
    case '고베':
      return '#F5736E';
    case '나라':
      return '#7E6FD9';

    default:
      return '#33B893';
  }
}};
`;

function SubHeader({title}) {
    return (
      <div>
        <CityIndicator>
          <IndicatorText title={title}>
          {title}
          </IndicatorText>
          </CityIndicator>
    </div>
    )
  }

export default SubHeader;
let cityListData = ["오사카 시내", "교토", "고베", "나라"]

let cityMetaData = [
    {
        name: "오사카 시내",
        engName : "osaka",
        isSelected: false,
        attributes: {
            isMultiRegion: false, // 교통패스이 필요하냐 : 3개 + 지역
            isHeavyTourist: false, // 입장권 패스가 필요하냐 : 합이 > 2000 or 3개이상 가냐
            isUsj : false,  // usj 포함 여부
            isStillJuyupass : false, // 그래도 주유패스
            passSum : 0 // 주유패스로 되는곳들의 합
        }
    }
    ,
    {
        name: "교토",
        engName : "kyoto",
        isSelected: false,
        attributes: {
            isOneDay: false, // 당일 인지
            isMultiRegion: false, // 여러군데를 가냐. 버스권이 필요한지
            stayLocation: "umeda" // 우메다 출발이면 한큐, 난바면 게이한, umeda, nanba, etc
        }
    }
    ,
    {
        name: "고베",
        engName : "kobe",
        isSelected: false,
        attributes: {
            isOneDay: false // 당일 일시 한신 패스
        }
    }
    ,
    {
        name: "나라",
        engName : "nara",
        isSelected: false,
        attributes: {
            isMultiCity: false // 여러도시를 같이 갈 시 간사이 스루
        }
    }
]

let osakaLandmarkData = [
    {
        id: 0,
        name: '오사카성',
        region: '오사카성',
        price: 600,
        inJuyupass: true,
        inEpass: false
    },
    {
        id: 1,
        name: '도톤보리 리버크루즈',
        region: '난바',
        price: 1000,
        inJuyupass: true,
        inEpass: true
    },
    {
        id: 2,
        name: '덴노지 동물원',
        region: '텐노지',
        price: 500,
        inJuyupass: true,
        inEpass: false
    },
    {
        id: 3,
        name: '츠텐카쿠 전망대',
        region: '텐노지',
        price: 900,
        inJuyupass: true,
        inEpass: true
    },
    {
        id: 4,
        name: '오사카 주택박물관',
        region: '우메다',
        price: 600,
        inJuyupass: true,
        inEpass: true
    },
    {
        id: 5,
        name: '덴포잔 대관람차',
        region: '오사카코',
        price: 800,
        inJuyupass: true,
        inEpass: false
    },
    {
        id: 6,
        name: '우메다 스카이빌딩 공중정원',
        region: '우메다',
        price: 1500,
        inJuyupass: true,
        inEpass: true
    },
    {
        id: 7,
        name: '햅파이브 대관람차',
        region: '우메다',
        price: 600,
        inJuyupass: true,
        inEpass: true
    },
    {
        id: 8,
        name: '시텐노지 사원',
        region: '텐노지',
        price: 400,
        inJuyupass: true,
        inEpass: false
    },
    {
        id: 9,
        name: '레고랜드 디스커버리 센터',
        region: '오사카코',
        price: 2800,
        inJuyupass: true,
        inEpass: true
    },
    {
        id: 10,
        name: '한큐백화점',
        region: '우메다',
        price: null,
        inJuyupass: null,
        inEpass: null
    },
    {
        id: 11,
        name: '도톤보리 글리코상',
        region: '난바',
        price: null,
        inJuyupass: null,
        inEpass: null
    },
    {
        id: 12,
        name: '신사이바시',
        region: '난바',
        price: null,
        inJuyupass: null,
        inEpass: null
    },
    {
        id: 13,
        name: '구로몬시장',
        region: '난바',
        price: null,
        inJuyupass: null,
        inEpass: null
    },
    {
        id: 14,
        name: '하루카스전망대',
        region: '텐노지',
        price: null,
        inJuyupass: null,
        inEpass: null
    },
    {
        id: 15,
        name: 'USJ',
        region: 'USJ',
        price: null,
        inJuyupass: null,
        inEpass: null
    }
]

let kyotoLandmarkData = [
    {
        id: 0,
        name: '청수사(기요미즈데라)',
        region: '교토 시내'
    }
    ,
    {
        id: 1,
        name: '니시키 시장',
        region: '교토 시내'
    }
    ,
    { 
        id: 2, 
        name: '기온거리', 
        region: '교토 시내' 
    },
    { 
        id: 3, 
        name: '산넨자카', 
        region: '교토 시내' 
    },
    { 
        id: 4, 
        name: '니넨자카', 
        region: '교토 시내' 
    },
    { 
        id: 5, 
        name: '금각사', 
        region: '금각사' 
    },
    { 
        id: 6, 
        name: '은각사', 
        region: '은각사 근처' 
    },
    { 
        id: 7, 
        name: '철학의 길', 
        region: '은각사 근처' 
    },
    { 
        id: 8, 
        name: '아라시야마', 
        region: '아라시야마' 
    },
    { 
        id: 9, 
        name: '후시미이나리 신사', 
        region: '후시미이나리 신사' 
    }
]

let selectedOptionsData = [
    {
        name : '오사카 시내',
        options : []
    }
    ,
    {
        name : '교토',
        options : []
    }
]

let resultMetaData = [
    {
        id : 0,
        title : "오사카 주유패스가 <br/>최고의 선택이 될 거에요!",
        reason : [
            
            "<strong>같은 날 3개 이상의 관광지</strong>에 입장하고, <strong>3번 이상 지하철</strong>을 탄다면 <strong>주유패스 1일권</strong>을 구매하는 게 가장 이득이에요!",
            "<strong>[지역]</strong>에 방문하시면 최소 3번 지하철을 타실거에요(1회당 240엔, <Strong>총 720+엔</Strong>). 거기에 선택한 관광지 비용이 <Strong>[비용]엔</Strong>이라서, 주유패스 1일권을 사면 <Strong>2800엔으로 더 저렴해요!</Strong>",
            "주유패스는 1일권 / 2일권이 나눠져 있으니 일정에 맞게 선택해보세요!"
        ],
        caution : [
            // "우메다공중정원은 주유패스로 16시까지 이용 가능하니 주의하세요!",
            // "우메다공중정원, 도톤보리리버크루즈가 포함되어 있다면 2개 이상의 관광지만 입장해도 이득!",
            "선택한 옵션 가격 :  [옵션 가격]"

        ],
        product : [
            {
                id : 116114,
                name : "오사카 주유패스 1/2일권",
                //url: "https://www.myrealtrip.com/offers/116114",
                url : "https://myrealtrip.onelink.me/5W8Y/qa15q6vw",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/116114/680565_regular_medium_1663230218.jpg?1663230218",
                review_score : 4.9,
                review_count : 8884,
                price : "26,800원~"
            }
        ]
    }
    ,
    {
        id : 16,
        title : "오사카 주유패스를 가장 추천해요!",
        reason : [
            "<strong>같은 날 3개 이상의 관광지</strong>에 입장하고, <strong>3번 이상 지하철</strong>을 탄다면 <strong>주유패스 1일권</strong>을 구매하는 게 가장 이득이에요!",
            "<strong>[지역]</strong>에 방문하시면 최소 3번 지하철을 타실거에요(1회당 240엔, 총 720+엔). 선택한 관광지는 총 [비용]엔이네요.",
            "어찌될지 모르는 일정, 저렴한 패스가 좋은 선택이 될 수 있어요. 1~2개만 더 방문해도 저렴한 <Strong>주유패스</Strong>를 고려해보세요!",
        ],
        caution : [
            // "우메다공중정원은 주유패스로 16시까지 이용 가능하니 주의하세요!",
            "우메다 공중정원(1500엔), 도톤보리리버크루즈(1000엔)가 포함되어 있다면 2개 이상의 관광지만 입장해도 이득!",
            "선택한 옵션 가격 : [옵션 가격]"

        ],
        product : [
            {
                id : 116114,
                name : "오사카 주유패스 1/2일권",
                //url: "https://www.myrealtrip.com/offers/116114",
                url: "https://myrealtrip.onelink.me/5W8Y/pu5bqdhq",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/116114/680565_regular_medium_1663230218.jpg?1663230218",
                review_score : 4.9,
                review_count : 8884,
                price : "26,800원~"
            }
        ]
    }
    ,
    {
        id : 1,
        title : "E-Pass와 별도의 교통권을<br/>사용하는 게 가장 좋아보여요!",
        reason : [
            "지하철 이동이 많지 않고, <strong>같은 날 3개 이상의 관광지</strong>에 입장한다면 <strong>E-pass + 별도의 교통권</strong>을 구매하는게 가장 이득이에요",
            "[지역]에만 방문하시네요. 그러면 이동이 많지 않은 것 같아요(1회 평균 240엔). E-pass 1일권을 사면 <Strong>2000엔이니 관광지 입장료를 보고 결정하세요!</Strong>",
           
        ],
        caution :  [
            "지하철/버스를 3번 이상 타거나 주유패스에만 포함되는 관광지에 가고 싶다면 주유패스가 더 유연하니 주유패스도 함께 고려해보세요!",
            "가격 팁 : 도톤보리 리버크루즈(1000엔), 우메다 스카이빌딩 공중정원(1500엔)이니 둘 중 하나만 포함해서 가도 충분히 이득일거에요!"
        ],
        product : [
            {
                id : 122989,
                name : "E-패스",
                //url: "https://www.myrealtrip.com/offers/122989",
                url: "https://myrealtrip.onelink.me/5W8Y/7tkqvy7a",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/122989/696973_regular_medium_1666069564.jpg?1666069564",
                review_score : 5.0,
                review_count : 2,
                price : "19,100원~"
            },
            {
                id : 116114,
                name : "오사카 주유패스 1/2일권",
                //url: "https://www.myrealtrip.com/offers/116114",
                url: "https://myrealtrip.onelink.me/5W8Y/3c45gxgc",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/116114/680565_regular_medium_1663230218.jpg?1663230218",
                review_score : 4.9,
                review_count : 8884,
                price : "26,800원~"
            }
        ]
    }
    ,
    {
        id : 2,
        title : "엔조이 에코카드/오사카 메트로 패스를 추천해요!",
        reason : [
            "여유로운 일정을 즐기신다면 오사카 시영 지하철, 트램 버스를 마음껏 승차할 수 있는 <strong>엔조이 에코카드/오사카 메트로 패스</strong>를 추천드려요!",
            "오사카 지하철은 1회당 평균 240-390엔이에요. <strong>주말에는 엔조이 에코카드</strong>가 더 저렴하고 (주말 600엔, 평일 800엔), <strong>주중에는 오사카 메트로 패스</strong>가 더 저렴해요 (평일/주말 700엔)",
            // "어찌될지 모르는 일정, 저렴한 패스가 좋은 선택이 될 수 있어요"
         ],
        caution : [
            "오사카 관광 일정을 하루/이틀에 몰고 주유패스를 구매해, 오사카 여행을 제대로 뽕 뽑는 분들이 많답니다. 가성비 있는 여행을 위해 주유패스도 함께 고려해보세요!",
            "엔조이 에코카드: 지하철 역내 발매기 (전 역에 설치)"
        ],
        product : [
            {
                id : 133355,
                name : "오사카 메트로 패스",
                //url: "https://www.myrealtrip.com/offers/133355",
                url: "https://myrealtrip.onelink.me/5W8Y/rlbgtz4e",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/133355/806879_large_1683009448.jpg?1683009448",
                review_score : 5.0,
                review_count : 1,
                price : "6,300원~"
            },
            {
                id : 116114,
                name : "오사카 주유패스 1/2일권",
                //url: "https://www.myrealtrip.com/offers/116114",
                url: "https://myrealtrip.onelink.me/5W8Y/o9kru16x",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/116114/680565_regular_medium_1663230218.jpg?1663230218",
                review_score : 4.9,
                review_count : 8884,
                price : "26,800원~"
            }

        ]
    }
    ,
    {
        id : 3,
        title : "개별 버스/지하철 승차권을 <br/>구매하는 것을 추천해요!",
        reason : [
            "오사카에서 이동도 적게 하고, 관광지 입장도 많이 하지 않는다면 <strong>교통패스보다는 이코카 카드를 충전</strong>해서 사용하는 게 더 이득이에요!"
        ],
        caution : [
            "오사카에는 많은 지하철 노선들이 있으니 미리 알아보고 가셔도 좋아요"
        ],
        product : [
            {
                id : 121510,
                name : "이코카 카드",
                //url: "https://www.myrealtrip.com/offers/121510",
                url: "https://myrealtrip.onelink.me/5W8Y/tid3gmlx",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/121510/681737_regular_medium_1663547117.jpg?1663547117",
                review_score : 4.6,
                review_count : 16,
                price : "23,300원~"
            }
        ]
    }
    ,
    {
        id : 4,
        title : "버스 1일권 + 이코카 카드가 <br/>좋은 선택이 될 거에요!",
        reason : [
            "교토에서 버스를 하루에 4회 이상 탄다면 버스 1일권이 더 저렴해요!",
            "<strong>[지역]</strong>에 방문을 계획하시군요. 만일 하루에 방문을 계획하신다면, 4회 이상 버스를 타실거에요. 그러면 <strong>버스 1일권</strong>을 추천해요",
            "교토에서 <strong>1박 이상</strong>을 한다면, <strong>지하철 패스보다는 개별 티켓 혹은 이코카 카드</strong> 충전해서 교토로 이동하는 것을 추천해요"
        ],
        caution : [
            "교토는 지하철보다 버스 이동이 편한 곳들이 많답니다. 어떤 교통편을 이용하는지 함께 알아보세요",
            "버스 1일권은 관광 안내소, 주요 버스 정류장 자판기, 버스 내, 지하철역에서 구매할 수 있어요"
        ],
        product : [
            {
                id : 121510,
                name : "이코카 카드",
                //url: "https://www.myrealtrip.com/offers/121510",
                url: "https://myrealtrip.onelink.me/5W8Y/2pgvhkdc",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/121510/681737_regular_medium_1663547117.jpg?1663547117",
                review_score : 4.6,
                review_count : 16,
                price : "23,300원~"
            }
        ]
    }
    ,
    {
        id : 5,
        title : "이코카 카드를 추천 드려요!",
        reason : [
            "교토에서 <strong>1박 이상</strong>을 한다면, <strong>지하철 패스보다는 개별 티켓 혹은 이코카 카드</strong> 충전해서 교토로 이동하는 것을 추천드려요",
            "하루에 <strong>[지역]</strong>만 방문한다면, <strong>버스 1일권 보다는 개별 승차권이나 이코카 카드를 충전해서 사용</strong>하는게 더 좋아요!"
        ],
        caution : [
            "교토는 지하철보다 버스 이동이 편한 곳들이 많답니다. 어떤 교통편을 이용하는지 함께 알아보세요",
            "만일 교토에서 버스를 하루에 4회 이상 타신다면, 교토 버스 1일권이 더 저렴하니 함께 고려해보세요"
        ],
        product : [
            {
                id : 121510,
                name : "이코카 카드",
                //url: "https://www.myrealtrip.com/offers/121510",
                url: "https://myrealtrip.onelink.me/5W8Y/e144ofeu",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/121510/681737_regular_medium_1663547117.jpg?1663547117",
                review_score : 4.6,
                review_count : 16,
                price : "23,300원~"
            }
        ]
    }
    ,
    {
        id : 6,
        title : "한큐 패스와 <br/>이코카 카드를 추천해요!",
        reason : [
            "교토로 <strong>당일치기 여행</strong>을 떠나는데 <strong>출발 위치가 우메다 근처</strong>라면 <strong>한큐 패스</strong>를 구매하는게 가장 이득이에요!",
            "오사카에서 교토까지 지하철 편도로 400-500엔이라서, 왕복 이용만 해도 한큐 패스(800엔)가 더 저렴해요.",
            "교토에서 [지역]만 방문한다면, <strong>버스 1일권 보다는 개별 승차권이나 이코카 카드</strong>를 충전해서 사용하는게 더 좋아요!"
        ],
        caution : [
            "교토는 지하철보다 버스 이동이 편한 곳들이 많답니다. 어떤 교통편을 이용하는지 함께 알아보세요",
            "교토에서 버스를 하루에 4회 이상 타신다면, 교토 버스 1일권이 더 저렴하니 함께 고려해보세요"
        ],
        product : [
            {
                id : 102364,
                name : "한큐 패스",
                //url: "https://www.myrealtrip.com/offers/102364",
                url: "https://myrealtrip.onelink.me/5W8Y/vivl0k1m",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/102364/553136_regular_medium_1611721634.jpg?1611721634",
                review_score : 4.9,
                review_count : 4719,
                price : "6,930원~"
            }
            ,
            {
                id : 121510,
                name : "이코카 카드",
                //url: "https://www.myrealtrip.com/offers/121510",
                url: "https://myrealtrip.onelink.me/5W8Y/j3busa8n",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/121510/681737_regular_medium_1663547117.jpg?1663547117",
                review_score : 4.6,
                review_count : 16,
                price : "23,300원~"
            }
        ]
    }
    ,
    {
        id : 7,
        title : "한큐패스와 버스 1일권을 <br/>함께 사는 것을 추천해요!",
        reason : [
            "교토로 <strong>당일치기 여행</strong>을 떠나는데 <strong>출발 위치가 우메다 근처</strong>라면 <strong>한큐 패스</strong>를 구매하는게 가장 이득이에요!",
            "오사카에서 교토까지 지하철 편도로 400-500엔이라서, 왕복 이용만 해도 한큐 패스(800엔)가 더 저렴해요.",
            "<strong>[지역]</strong>에 방문을 계획하시군요. 그러면 4회 이상 버스를 타실 것 같아요. 교토에서 버스를 하루에 4회 이상 탄다면 버스 1일권이 더 저렴해요!",
        ],
        caution : [
            "교토는 지하철보다 버스 이동이 편한 곳들이 많답니다. 어떤 교통편을 이용하는지 함께 알아보세요",
            "버스 1일권은 관광 안내소, 주요 버스 정류장 자판기, 버스 내, 지하철역에서 구매할 수 있어요"
        ],
        product : [
            {
                id : 102364,
                name : "한큐 패스",
                //url: "https://www.myrealtrip.com/offers/102364",
                url: "https://myrealtrip.onelink.me/5W8Y/m0mdq8gj",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/102364/553136_regular_medium_1611721634.jpg?1611721634",
                review_score : 4.9,
                review_count : 4719,
                price : "6,930원~"
            }
        ]
    }
    ,
    {
        id : 8,
        title : "게이한 패스를 구매하시고 <br/>교토에선 개별로 버스를 이용하세요!",
        reason : [
            "교토로 <strong>당일치기 여행</strong>을 떠나는데 <strong>출발 위치가 난바 근처</strong>라면 <strong>게이한 패스</strong>를 구매하는게 가장 이득이에요!",
            "오사카에서 교토까지 지하철 편도로 400-500엔이라서, 왕복 이용만 해도 게이한 패스(800엔)가 더 저렴해요.",
            "교토에서 [지역]만 방문한다면, <strong>버스 1일권 보다는 개별 승차권이나 이코카 카드</strong>를 충전해서 사용하는게 더 좋아요!"
        ],
        caution : [
            "교토는 지하철보다 버스 이동이 편한 곳들이 많답니다. 어떤 교통편을 이용하는지 함께 알아보세요",
            "만일 교토에서 버스를 하루에 4회 이상 타신다면, 교토 버스 1일권이 더 저렴하니 함께 고려해보세요"
        ],
        product : [
            {
                id : 126290,
                name : "게이한 패스 1일권",
                //url: "https://www.myrealtrip.com/offers/121409",
                // url: "https://myrealtrip.onelink.me/5W8Y/87oklsts",
                url: "https://myrealtrip.onelink.me/5W8Y?af_ios_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126290%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result8-126290&af_android_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126290%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result8-126290&af_web_dp=https%3A%2F%2Fwww.myrealtrip.com%2Foffers%2F126290%3Futm_source%3Dmrt_passfinder%26utm_campaign%3Dpassfinder-result8-126290&pid=mrt_passfinder&af_click_lookback=7d&af_channel=promotion-page&af_xp=custom&is_retargeting=true&af_inactivity_window=1d&c=passfinder-result8-126290&af_dp=mrt://offers/126290&deep_link_value=mrt://offers/126290&af_force_deeplink=true",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/126290/776485_large_1678088693.jpg?1678088693",
                review_score : 5.0,
                review_count : 28,
                price : "8,800원~"
            }
            ,
            {
                id : 126319,
                name : "게이한 패스 2일권",
                //url: "https://www.myrealtrip.com/offers/121409",
                // url: "https://myrealtrip.onelink.me/5W8Y/87oklsts",
                url: "https://myrealtrip.onelink.me/5W8Y?af_ios_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126319%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result8-126319&af_android_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126319%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result8-126319&af_web_dp=https%3A%2F%2Fwww.myrealtrip.com%2Foffers%2F126319%3Futm_source%3Dmrt_passfinder%26utm_campaign%3Dpassfinder-result8-126319&pid=mrt_passfinder&af_click_lookback=7d&af_channel=promotion-page&af_xp=custom&is_retargeting=true&af_inactivity_window=1d&c=passfinder-result8-126319&af_dp=mrt://offers/126319&deep_link_value=mrt://offers/126319&af_force_deeplink=true",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/126319/776495_large_1678088925.jpg?1678088925",
                review_score : 5.0,
                review_count : 28,
                price : "13,800원~"
            }
            ,
            {
                id : 121510,
                name : "이코카 카드",
                //url: "https://www.myrealtrip.com/offers/121510",
                url: "https://myrealtrip.onelink.me/5W8Y/qix4s5v1",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/121510/681737_regular_medium_1663547117.jpg?1663547117",
                review_score : 4.6,
                review_count : 16,
                price : "23,300원~"
            }
        ]
    }
    ,
    {
        id : 9,
        title : "게이한 패스와 버스 1일권을 <br/>함께 사는 것을 추천해요!",
        reason : [
            "교토로 <strong>당일치기 여행</strong>을 떠나는데 <strong>출발 위치가 난바 근처</strong>라면 <strong>게이한 패스</strong>를 구매하는게 가장 이득이에요!",
            "오사카에서 교토까지 지하철 편도로 400-500엔이라서, 왕복 이용만 해도 게이한 패스(800엔)가 더 저렴해요.",
            "<strong>[지역]</strong>에 방문을 계획하시군요. 그러면 4회 이상 버스를 타실 것 같아요. 교토에서 버스를 하루에 4회 이상 탄다면 버스 1일권이 더 저렴해요!",
        ],
        caution : [
            "교토는 지하철보다 버스 이동이 편한 곳들이 많답니다. 어떤 교통편을 이용하는지 함께 알아보세요",
            "버스 1일권은 관광 안내소, 주요 버스 정류장 자판기, 버스 내, 지하철역에서 구매할 수 있어요"
        ],
        product : [
            {
                id : 126290,
                name : "게이한 패스 1일권",
                url: "https://myrealtrip.onelink.me/5W8Y?af_ios_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126290%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result9-126290&af_android_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126290%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result9-126290&af_web_dp=https%3A%2F%2Fwww.myrealtrip.com%2Foffers%2F126290%3Futm_source%3Dmrt_passfinder%26utm_campaign%3Dpassfinder-result9-126290&pid=mrt_passfinder&af_click_lookback=7d&af_channel=promotion-page&af_xp=custom&is_retargeting=true&af_inactivity_window=1d&c=passfinder-result9-126290&af_dp=mrt://offers/126290&deep_link_value=mrt://offers/126290&af_force_deeplink=true",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/126290/776485_large_1678088693.jpg?1678088693",
                review_score : 5.0,
                review_count : 28,
                price : "8,800원~"
            }
            ,
            {
                id : 126319,
                name : "게이한 패스 2일권",
                url: "https://myrealtrip.onelink.me/5W8Y?af_ios_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126319%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result9-126319&af_android_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126319%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result9-126319&af_web_dp=https%3A%2F%2Fwww.myrealtrip.com%2Foffers%2F126319%3Futm_source%3Dmrt_passfinder%26utm_campaign%3Dpassfinder-result9-126319&pid=mrt_passfinder&af_click_lookback=7d&af_channel=promotion-page&af_xp=custom&is_retargeting=true&af_inactivity_window=1d&c=passfinder-result9-126319&af_dp=mrt://offers/126319&deep_link_value=mrt://offers/126319&af_force_deeplink=true",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/126319/776495_large_1678088925.jpg?1678088925",
                review_score : 5.0,
                review_count : 28,
                price : "13,800원~"
            }
        ]
    }
    ,
    {
        id : 10,
        title : "게이한/한큐 패스를 구매하시고 <br/>교토에선 개별로 버스를 이용하세요!",
        reason : [
            "교토로 <strong>당일치기 여행</strong>을 떠나신다면 <strong>게이한 패스나 한큐 패스</strong>를 구매하는게 가장 이득이에요!",
            "오사카에서 교토까지 지하철 편도로 400-500엔이라서, 왕복 이용만 해도 게이한/한큐 패스(800엔)가 더 저렴해요.",
            "교토에서 [지역]만 방문한다면, <strong>버스 1일권 보다는 개별 승차권이나 이코카 카드</strong>를 충전해서 사용하는게 더 좋아요!"
        ],
        caution : [
            "교토는 지하철보다 버스 이동이 편한 곳들이 많아요. 어떤 교통편을 이용하는지 함께 알아보세요",
            "만일 교토에서 버스를 하루에 4회 이상 타신다면, 교토 버스 1일권이 더 저렴하니 함께 고려해보세요"
        ],
        product : [
            {
                id : 102364,
                name : "한큐 패스",
                //url: "https://www.myrealtrip.com/offers/102364",
                url: "https://myrealtrip.onelink.me/5W8Y/cyeuydy7",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/102364/553136_regular_medium_1611721634.jpg?1611721634",
                review_score : 4.9,
                review_count : 4719,
                price : "6,930원~"
            }
            ,
            {
                id : 126290,
                name : "게이한 패스 1일권",
                url: "https://myrealtrip.onelink.me/5W8Y?af_ios_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126290%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result10-126290&af_android_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126290%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result10-126290&af_web_dp=https%3A%2F%2Fwww.myrealtrip.com%2Foffers%2F126290%3Futm_source%3Dmrt_passfinder%26utm_campaign%3Dpassfinder-result10-126290&pid=mrt_passfinder&af_click_lookback=7d&af_channel=promotion-page&af_xp=custom&is_retargeting=true&af_inactivity_window=1d&c=passfinder-result10-126290&af_dp=mrt://offers/126290&deep_link_value=mrt://offers/126290&af_force_deeplink=true",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/126290/776485_large_1678088693.jpg?1678088693",
                review_score : 5.0,
                review_count : 28,
                price : "8,800원~"
            }
            ,
            {
                id : 126319,
                name : "게이한 패스 2일권",
                url: "https://myrealtrip.onelink.me/5W8Y?af_ios_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126319%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result10-126319&af_android_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126319%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result10-126319&af_web_dp=https%3A%2F%2Fwww.myrealtrip.com%2Foffers%2F126319%3Futm_source%3Dmrt_passfinder%26utm_campaign%3Dpassfinder-result10-126319&pid=mrt_passfinder&af_click_lookback=7d&af_channel=promotion-page&af_xp=custom&is_retargeting=true&af_inactivity_window=1d&c=passfinder-result10-126319&af_dp=mrt://offers/126319&deep_link_value=mrt://offers/126319&af_force_deeplink=true",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/126319/776495_large_1678088925.jpg?1678088925",
                review_score : 5.0,
                review_count : 28,
                price : "13,800원~"
            }
        ]
    }
    ,
    {
        id : 11,
        title : "게이한/한큐패스와 <br/>버스 1일권을 추천해요!",
        reason : [
            "교토로 <strong>당일치기 여행</strong>을 떠나신다면 <strong>게이한 패스나 한큐 패스</strong>를 구매하는게 가장 이득이에요!",
            "오사카에서 교토까지 지하철 편도로 400-500엔이라서, 왕복 이용만 해도 게이한 패스(800엔)가 더 저렴해요.",
            "<strong>[지역]</strong>에 방문을 계획하시군요. 그러면 4회 이상 버스를 타실 것 같아요. 교토에서 버스를 하루에 4회 이상 탄다면 버스 1일권이 더 저렴해요!",
        ],
        caution : [
            "교토는 지하철보다 버스 이동이 편한 곳들이 많답니다. 어떤 교통편을 이용하는지 함께 알아보세요",
            "버스 1일권은 관광 안내소, 주요 버스 정류장 자판기, 버스 내, 지하철역에서 구매할 수 있어요"
        ],
        product : [
            {
                id : 102364,
                name : "한큐 패스",
                //url: "https://www.myrealtrip.com/offers/102364",
                url: "https://myrealtrip.onelink.me/5W8Y/ox8xfq7i",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/102364/553136_regular_medium_1611721634.jpg?1611721634",
                review_score : 4.9,
                review_count : 4719,
                price : "6,930원~"
            }
            ,
            {
                id : 126290,
                name : "게이한 패스 1일권",
                url: "https://myrealtrip.onelink.me/5W8Y?af_ios_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126290%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result11-126290&af_android_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126290%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result11-126290&af_web_dp=https%3A%2F%2Fwww.myrealtrip.com%2Foffers%2F126290%3Futm_source%3Dmrt_passfinder%26utm_campaign%3Dpassfinder-result11-126290&pid=mrt_passfinder&af_click_lookback=7d&af_channel=promotion-page&af_xp=custom&is_retargeting=true&af_inactivity_window=1d&c=passfinder-result11-126290&af_dp=mrt://offers/126290&deep_link_value=mrt://offers/126290&af_force_deeplink=true",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/126290/776485_large_1678088693.jpg?1678088693",
                review_score : 5.0,
                review_count : 28,
                price : "8,800원~"
            }
            ,
            {
                id : 126319,
                name : "게이한 패스 2일권",
                url: "https://myrealtrip.onelink.me/5W8Y?af_ios_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126319%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result11-126319&af_android_url=https%3A%2F%2Fwww.myrealtrip.com%2Fkakao-auto-login%3Fredirect_url%3Dhttps%253A%252F%252Fwww.myrealtrip.com%252Foffers%252F126319%253Futm_source%253Dmrt_passfinder%2526utm_campaign%253Dpassfinder-result11-126319&af_web_dp=https%3A%2F%2Fwww.myrealtrip.com%2Foffers%2F126319%3Futm_source%3Dmrt_passfinder%26utm_campaign%3Dpassfinder-result11-126319&pid=mrt_passfinder&af_click_lookback=7d&af_channel=promotion-page&af_xp=custom&is_retargeting=true&af_inactivity_window=1d&c=passfinder-result11-126319&af_dp=mrt://offers/126319&deep_link_value=mrt://offers/126319&af_force_deeplink=true",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/126319/776495_large_1678088925.jpg?1678088925",
                review_score : 5.0,
                review_count : 28,
                price : "13,800원~"
            }
        ]
    }
    ,
    {
        id : 12,
        title : "한신 투어리스트 패스가 <br/>최고의 선택일거에요!",
        reason : [
            "고베로 당일치기 여행을 떠난다면 <strong>한신 투어리스트 패스를 구매하는게 가장 이득</strong>이에요!",
            "오사카 난바역에서 고베 편도로 410엔이라서, 1일권에 500엔인 한신 투어리스트 패스를 사면 왕복만 해도 더 저렴해요"
        ],
        caution : null,
        product : [
            {
                id : 135612,
                name : "한신 투어리스트 패스",
                //url: "https://www.myrealtrip.com/offers/116280",
                url: "https://myrealtrip.onelink.me/5W8Y/zmjnq1qk",
                image_url : "https://d6bztw1vgnv55.cloudfront.net/1504819/offer_photos/135612/834703_regular_medium_1687163865.jpg",
                review_score : 5.0,
                review_count : 1,
                price : "4,400원~"
            }
        ]
    }
    ,
    {
        id : 13,
        title : "별도 패스 없이 <br/>개별 교통권을 추천해요!",
        reason : [
            "고베에서 1박 이상을 한다면 <strong>개별 티켓을 구매해서 고베로 이동</strong>하는게 더 이득이에요! <strong>(이코카 카드 충전해서 사용하는 것도 가능해요!)</strong>",
            "하루에 왕복을 하지 않는다면 굳이 패스를 살 필요 없어요!"
        ],
        caution : null,
        product : [
            {
                id : 121510,
                name : "이코카 카드",
                //url: "https://www.myrealtrip.com/offers/121510",
                url: "https://myrealtrip.onelink.me/5W8Y/wwobuic6",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/121510/681737_regular_medium_1663547117.jpg?1663547117",
                review_score : 4.6,
                review_count : 16,
                price : "23,300원~"
            }
        ]
    }
    ,
    {
        id : 14,
        title : "간사이 쓰루 패스를 추천드려요!",
        reason : [
            "같은 날에 나라와 함께 다른 근교 도시 (교토, 고베)도 함께 간다면 <strong>간사이 쓰루패스</strong>를 구매하세요",
            "간사이 전체 지하철, 전철(JR 제외), 버스를 자유롭게 이용 가능해요!",
            "간사이 쓰루패스를 사용하시는 날에는 <strong>위에 추천드린 다른 패스 (오사카 메트로패스/한신/게이한/한큐 패스)</strong>는 필요하지 않으니 참고하세요!"
        ],
        caution : [
            "나라 주요 관광지는 JR나라역보다 긴테츠나라역과 가까우니 긴테츠나라선을 타시고 나라에 다녀오시는 걸 추천해요!(JR나라선은 버스를 따로 타셔야해요)"
        ],
        product : [
            {
                id : 102363,
                name : "간사이 쓰루 패스",
                //url: "https://www.myrealtrip.com/offers/102363",
                url: "https://myrealtrip.onelink.me/5W8Y/mdy3nemi",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/102363/680630_regular_medium_1663216480.jpg?1663216480",
                review_score : 4.8,
                review_count : 993,
                price : "48,800원~"
            }
        ]
    }
    ,
    {
        id : 15,
        title : "별도 패스 없이 <br/>개별 교통권을 추천해요!",
        reason : [
            "같은 날에 나라 외 다른 근교 도시를 가지 않는다면, <strong>개별 티켓을 구매</strong>해서 나라로 이동하는 게 가장 나아요 (<Strong>이코카 카드</Strong> 충전해서 사용하는 것도 가능해요!)",
            "하루에 모두 이동하는 것이 아니라면 굳이 패스가 필요 없어요"
        ],
        caution : [
            "나라 주요 관광지는 JR나라역보다 긴테츠나라역과 가까우니 긴테츠나라선을 타시고 나라에 다녀오시는 걸 추천해요!(JR나라선은 버스를 따로 타셔야해요)"
        ],
        product : [
            {
                id : 121510,
                name : "이코카 카드",
                //url: "https://www.myrealtrip.com/offers/121510",
                url: "https://myrealtrip.onelink.me/5W8Y/xyh6maz1",
                image_url : "https://d2ur7st6jjikze.cloudfront.net/offer_photos/121510/681737_regular_medium_1663547117.jpg?1663547117",
                review_score : 4.6,
                review_count : 16,
                price : "23,300원~"
            }
        ]
    }
]

export { cityMetaData, cityListData, osakaLandmarkData, kyotoLandmarkData, selectedOptionsData,resultMetaData };
import React, { useEffect } from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ButtonSection = styled.div`

  padding-top: 130px;  
  padding-bottom: 32px;  
`;

const SectionTitleWithSub = styled.div`
    --tw-text-opacity: 1!important;
    color: rgb(42 48 55/var(--tw-text-opacity))!important;
    font-weight: 700!important;
    font-size: 24px!important;
    text-align: center!important;
    line-height: 1.4;
    padding-bottom: 5px;
`;

const SectionSubtitle = styled.div`
    --tw-text-opacity: 1!important;
    color: rgb(134 142 150/var(--tw-text-opacity))!important;
    font-size: 16px!important;
    text-align: center!important;
    margin-top: 4px!important;
`;

const NextWrpper = styled.div`

margin: 0px auto;
max-width: 480px;
display : flex;
justify-content: center;
align-items: center;

`;
const NextButton = styled.button`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    line-height: 1.25;
    border: 1px solid transparent;
    border-radius: 12px;
    padding: 0;
    cursor: pointer;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 90%;
    height: 56px;
    font-size: 16px;
    padding: 0 16px 0 16px;
    color: #ffffff;
    background-color: #2b96ed;
    border-color: #2b96ed;
    font-weight: 700;
`;
const Header = styled.div`
position: relative;
width: 100%;
height: auto;
z-index: auto;
background-color: rgb(255, 255, 255);
}
`;

const Nav = styled.nav`
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 72px;
    max-width: 1060px;
    margin: 0px auto;
    justify-content: center;
    height: 48px;
`;
const LogoContainer = styled.div`

`;

const LogoImg = styled.img`

  display: inline-block;
  border: 0px;
  width: 128px;
  vertical-align: middle;
  width: 92px;
`;
function Cover() {
    const navigate = useNavigate();

    const handleNextButton = (event) => {
    
        navigate('/main');
        // func(cityMeta);
        // func({cityName, selectedButtons, setCityMeta, cityMeta, selectedOptions, setSelectedOptions, optionsList})
    };
    
    
    useEffect(() => {
        localStorage.clear();
      }, [navigate]);

	return (
		<Wrapper>  
      <Header>
            <Nav>
              <LogoContainer>
              <LogoImg src="https://dffoxz5he03rp.cloudfront.net/icons/logo_mrt_v2_web.svg" alt="myrealtrip"/>
              </LogoContainer>
                  
              </Nav>
              </Header>

            <ButtonSection> 
                    <>

                    <SectionTitleWithSub>나에게 딱 맞는 <br/> 오사카 교통패스 찾기</SectionTitleWithSub>
                    <SectionSubtitle>너무너무 복잡한 오사카 패스들을 한 번에!</SectionSubtitle>
                    </>
            </ButtonSection> 

            <NextWrpper>

                    <NextButton onClick={handleNextButton}>10초 만에 찾아보기</NextButton>

                    {/*
                    <div style={{ width: '10px' }}></div>
                    <NextButton oonClick={handleShare}>공유하기</NextButton>
                    <AlarmBanner isVisible={isShared}>Link saved!</AlarmBanner>
                    */}
            </NextWrpper>
		</Wrapper>
	)
}

export default React.memo(Cover);


import {osakaLandmarkData,kyotoLandmarkData} from './data.js';


function editCityIsMultiRegion({cityName, selectedButtons, setCityMeta, cityMeta, optionsList, selectedOptions, setSelectedOptions}) {
    let landmarkData = optionsList ;
    let regionsArray = [];
    let cityMetaCopy = [...cityMeta];
    let selectedOptionsCopy = [...selectedOptions];
    let region;
    let price;
    let osakaJuyupassSum = 0;

    // 도시에 따라 다른 랜드마크 이용
    if (cityName === '오사카 시내') {
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isUsj = false;

        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isStillJuyupass = false;
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.passSum = 0;
    }

    // 랜드마크 별로 region array 만들기
    for (const landmark of selectedButtons) {
        region = landmarkData[landmarkData.findIndex((item) => item.name === landmark)].region;
        price = landmarkData[landmarkData.findIndex((item) => item.name === landmark)].price;
        if (cityName === '오사카 시내') {
            if (price) {
                osakaJuyupassSum += price;
            }
            
            if (region === 'USJ') {
                cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isUsj = true;
            } else {
                regionsArray.push(region);
            }
        } else {
            regionsArray.push(region);
        }
    }


    cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.passSum = osakaJuyupassSum;
 
    // set 으로 변환
    let regionSet = new Set(regionsArray);

    // region 개수가 3개 이상일시 cityMeta 변경
    if (regionSet.size >= 3) {
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isMultiRegion = true;
    } else {
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isMultiRegion = false;
    }

    // selectedOptionsCopy 변경
    selectedOptionsCopy[selectedOptionsCopy.findIndex((item) => item.name === cityName)].options = selectedButtons;

    // cityMeta & selectedoptions 변경   
    setCityMeta(cityMetaCopy);
    setSelectedOptions(selectedOptionsCopy);
    
}

function editOsakaIsHeavyTourist({cityName, setCityMeta, cityMeta, selectedOptions, buttonInput}) {
    let cityMetaCopy = [...cityMeta];
    let osakaLandmark = osakaLandmarkData;
    
    // button input true, isHeavyTourist = false, false, isHeavyTourist= true
    // 오사카 일때만 변경
    if (buttonInput && cityName === "오사카 시내") {
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isHeavyTourist = true;
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isStillJuyupass = false;
    } else {
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isHeavyTourist = false;
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isStillJuyupass = false;
    }
    
    cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isStillJuyupass = false;
    // selectedOptions 에 Epass 상품이 들어간다면, isMultiRegion = true, isStillJuyupass = true
    // let i = 0;
    // 이전에 고른 오사카 랜드 마크
    let options = selectedOptions[selectedOptions.findIndex((item) => item.name === cityName)].options;
    // button input 이 true (많이 갈거다) && multi region 은 false 면,
    if (buttonInput && cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isMultiRegion === false) {
        // 골랏던 랜드마크 중 확인해서 epass로 안되는곳이 잇다면 mutl region = true, isStillJyupass = true 로 주유패스 추천

        for (let i = 0; i < options.length && cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isStillJuyupass === false; i++) {
            const isEpassAvailable = osakaLandmark[osakaLandmark.findIndex((item) => item.name === options[i])].inEpass;
            if (isEpassAvailable === false) {
                cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isStillJuyupass = true;
                cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isMultiRegion = true;
            }
        }
    }

    // cityMeta 변경   
    setCityMeta(cityMetaCopy);
}

function editCityIsOneDay({cityName, setCityMeta, cityMeta, selectedOptions, buttonInput}) {
    let cityMetaCopy = [...cityMeta];

    // 교토, 고베 일때만 변경
    if (buttonInput && ["교토", "고베"].includes(cityName)) {
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isOneDay = true;
    } else {
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isOneDay = false;
    }

    // cityMeta 변경   
    setCityMeta(cityMetaCopy);
}

function editNaraIsMultiCity({cityName, setCityMeta, cityMeta, selectedOptions, buttonInput}) {
    let cityMetaCopy = [...cityMeta];

    // 나라 일때만 변경
    if (buttonInput && cityName === "나라") {
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isMultiCity = true;
    } else {
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.isMultiCity = false;
    }

    // cityMeta 변경   
    setCityMeta(cityMetaCopy);
}

function editKyotostayLocation({cityName, setCityMeta, cityMeta, selectedOptions, buttonInput}) {
    let cityMetaCopy = [...cityMeta];

    // 나라 일때만 변경
    if (buttonInput !== null && cityName === "교토") {
        cityMetaCopy[cityMetaCopy.findIndex((item) => item.name === cityName)].attributes.stayLocation = buttonInput;
    }

    // cityMeta 변경   
    setCityMeta(cityMetaCopy);

}

function getResult({cityName, cityMeta, selectedOptions}) {
    
    // 오사카 시내
    if (cityName === "오사카 시내") {
        let osakaChoices = cityMeta[cityMeta.findIndex((item) => item.name === cityName)]
        if (osakaChoices.attributes.isMultiRegion === true && osakaChoices.attributes.isHeavyTourist === true) {
            if (osakaChoices.attributes.passSum < 2000) {
                return 16
            } else {
                return 0
            }  
        } else if (osakaChoices.attributes.isMultiRegion === false && osakaChoices.attributes.isHeavyTourist === true) {
            return 1
        } else if (osakaChoices.attributes.isMultiRegion === true && osakaChoices.attributes.isHeavyTourist === false) {
            return 2
        } else {
            return 3
        }
    }

    // 교토
    if (cityName === "교토") {
        let kyotoChoices = cityMeta[cityMeta.findIndex((item) => item.name === cityName)]
        // selected options 받아서
        let stayLocation = kyotoChoices.attributes.stayLocation
        let kyotoSelectedOptions = selectedOptions[selectedOptions.findIndex((item) => item.name === "교토")].options;
        
        if (kyotoSelectedOptions.includes("아라시야마") || kyotoSelectedOptions.includes("후시미이나리 신사")) {
            stayLocation = "etc"
        }
        
        
        // if options include 아라시야마, 후시미이나리, staylocation => "etc"

        if (kyotoChoices.attributes.isOneDay) {
            switch(stayLocation) {
                case "umeda":
                    if (kyotoChoices.attributes.isMultiRegion) {
                        return 7
                    } else {
                        return 6
                    }
                case "nanba":
                    if (kyotoChoices.attributes.isMultiRegion) {
                        return 9
                    } else {
                        return 8
                    }
                case "etc":
                    if (kyotoChoices.attributes.isMultiRegion) {
                        return 11 // 한큐/게이한 + 버스1일
                    } else {
                        return 10 // 한큐/게이한 + 개별
                    }
                default:
                    return -1// "No stay location specified"
              }
        } else {
            if (kyotoChoices.attributes.isMultiRegion) {
                return 4
            } else {
                return 5
            }
        }
        
        
    }

    // 고베
    if (cityName === "고베") {
        let kobeChoices = cityMeta[cityMeta.findIndex((item) => item.name === cityName)]
        if (kobeChoices.attributes.isOneDay) {
            return 12
        } else {
            return 13
        }
    }

    // 나라
    if (cityName === "나라") {
        let naraChoices = cityMeta[cityMeta.findIndex((item) => item.name === cityName)]
        if (naraChoices.attributes.isMultiCity) {
            return 14
        } else {
            return 15
        }

    }
}

const getResultIndex = ({resultMetaData,cityName,cityMeta,selectedOptions}) => {
    if (!cityName) 
        return;
    return resultMetaData.findIndex((item) => item.id === getResult({cityName, cityMeta, selectedOptions}));
};
// {
//     id: 0,
//     name: '오사카성',
//     region: '오사카성',
//     price: 600,
//     inJuyupass: true,
//     inEpass: false
// },


const getRegionFromOptions = ({selectedOptions,cityName}) => {
    if (!selectedOptions) {
        return undefined;
      }
    
    // set 으로 변환
    let selectedLandmarks = selectedOptions[selectedOptions.findIndex((item) => item.name === cityName)].options;
    let regionIndex ;
    let regionArray = [];
    
    
    for (let i = 0; i < selectedLandmarks.length ; i++) {
        if (cityName === "오사카 시내"){
            regionIndex = osakaLandmarkData.findIndex((item) => item.name === selectedLandmarks[i]);
            regionArray.push(osakaLandmarkData[regionIndex].region);
        } else if (cityName === "교토"){
            regionIndex = kyotoLandmarkData.findIndex((item) => item.name === selectedLandmarks[i]);
            regionArray.push(kyotoLandmarkData[regionIndex].region);
        }
    }


    let regionSet = new Set(regionArray);

    return [...regionSet];


}


const getOptionsListWithPrice = ({selectedOptions,cityName}) => {
    if (!selectedOptions) {
        return undefined;
    }

    if (cityName !== "오사카 시내"){
        return 0;
    }
    let selectedLandmarks = selectedOptions[selectedOptions.findIndex((item) => item.name === cityName)].options;
    let text = '';
    let price;
    let name;
    for (let i = 0; i < selectedLandmarks.length ; i++) {
            let regionIndex = osakaLandmarkData.findIndex((item) => item.name === selectedLandmarks[i]);
            price = osakaLandmarkData[regionIndex].price;
         
            name = osakaLandmarkData[regionIndex].name;
            if (['USJ', '하루카스전망대'].includes(name)) {
                text = text + `${name}(패스로 이용불가), `;
            } else if (!price) {
                text = text + `${name}(무료), `;
            }
            else {
                text = text + `${name}(${price}엔), `;
            }

            

    }
    return text.slice(0, -2);
}

export {editCityIsMultiRegion, editOsakaIsHeavyTourist, editCityIsOneDay, editNaraIsMultiCity, editKyotostayLocation, getResult, getResultIndex, getRegionFromOptions, getOptionsListWithPrice}